import React, { useState } from "react";
import Page from "../../layout/Page";

export default function Medelvarde({ data }) {
    const [values, setValues] = useState("")
    const [text, setText] = useState("")

    function calculate() {
        try {
            const arr = values.split(",").map((a) => {
                if (!Number.isInteger(Number(a))) throw Error()
                return Number(a)
            })
            const average = arr.reduce((a, b) => a + b, 0) / arr.length;
            setText("Medelvärdet är: " + average.toFixed(2))
        }

        catch {
            setText("Ett fel inträffade. Kontrollera att du skrivit in rätt i fältet.")
        }
    }


    return (
        <Page title={"Räkna ut medelvärde"}>
            <br />
            <br />
            <h1>Räkna ut medelvärde</h1>
            <p>Medelvärdet går att räkna ut genom att summera alla värden i gruppen och sedan dividera med antalet värden. Om vi har en totalpopulation som saknar eller har mycket få extremvärden kan medelvärdet ofta vara ett bra mått att studera.</p>
            <p>Nedan har vi gjort en mycket enkel kalkylator som du kan använda för att beräkna medelvärdet för ett antal värden.</p>
            <h3 id="hur-r-knar-du-medelv-rdet">Hur räknar du ut medelvärdet?</h3>
            <p>Säg att vi har samma exempel som för medianen. Vi har en familj av individer i åldrarna 7, 13, 42, 23 och 45. Vi vill beräkna medelvärdet för denna familjen. Hur gör vi det?</p>
            <p>Genom att skriva in åldrar i en serie med ett kommatecken emellan kan du beräkna medelvärdet via vårt verktyg. För att beräkna medelvärdet för åldrarna i familjen skriver du följande: <em>7, 13, 42, 23, 45</em></p>
            <p>Om du skriver in talserien i textrutan och sedan klickar på “Räkna ut” kommer medelvärdet att skrivas ut.</p>
            <input
                type="text"
                value={values}
                onChange={(e) => setValues(e.target.value)}
            />
            <button onClick={calculate}>Räkna ut</button>
            <p>{text}</p>
            <br/>
            <br/>
        </Page>
    );
}